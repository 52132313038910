import { useMutation } from "react-query";

import { useCreateAPIClient } from "../../../hooks";
import { PurchaseSubscriptionRequest } from "../../../api/stripe/types";
import { useToggle } from "../../toggle/useToggle";

export const useCreateSetupIntent = () => {
  const apiClient = useCreateAPIClient();

  return useMutation(["setupIntent", "create"], () =>
    apiClient.stripeApi.createSetupIntent(),
  );
};

export const usePurchaseSubscription = () => {
  const apiClient = useCreateAPIClient();
  const isNewWebPricing = useToggle("featureWebPricing");

  return useMutation(
    ["subscription", "purchase"],
    (payload: PurchaseSubscriptionRequest) =>
      apiClient.stripeApi.purchaseSubscription({ ...payload, isNewWebPricing }),
  );
};
