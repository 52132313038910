export type Toggles = {
  exampleVariant: Prettify<Variant<string>>;
  featureOnboardingAppRating: Prettify<Variant<string>>;
  featureRevenueCatIsSubscribedWeb: boolean;
  featureWebPricing: boolean;
};

// Only used in the VERY rare case that we are offline and have no cache data
// everything defaults to disabled otherwise
export const InitialOfflineDefaultToggles: Partial<Toggles> = {
  exampleVariant: { payload: "example" },
  featureOnboardingAppRating: { payload: "4.9" },
};

export type ToggleNames = keyof Toggles;

type PureToggles = PickByType<Toggles, boolean>;
type PureToggleNames = keyof PureToggles;

export type Variants = Omit<Toggles, PureToggleNames>;
export type VariantNames = keyof Variants;

type Variant<T> = false | { payload: T };

type Prettify<T> = {
  [K in keyof T]: T[K];
} & NonNullable<unknown>;

type PickByType<T, Value> = {
  [P in keyof T as T[P] extends Value | undefined ? P : never]: T[P];
};
