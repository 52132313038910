import { useMemo } from "react";
import { Discount, Plan } from "../../../api";
import { calculateFinalPrice } from "../../Checkout/checkoutUtils";
import { useLocale } from "../../../hooks/useLocale";
import { usePlanLocale } from "../../SubscriptionPlans/hooks/usePlanLocale";
import { useToggle } from "../../toggle/useToggle";

type PlanPriceConfig = {
  plan: Plan;
  discount: Discount;
  withDecimals?: boolean;
};

const newPricing: Record<"Monthly" | "Quarterly" | "Yearly", number> = {
  Monthly: 24.99,
  Quarterly: 69.99,
  Yearly: 149.99,
};

export const usePlanPrice = ({
  plan,
  discount,
  withDecimals = false,
}: PlanPriceConfig) => {
  const locale = useLocale();
  const { planLocale } = usePlanLocale({ plan });
  const isNewWebPricing = useToggle("featureWebPricing");

  return useMemo(() => {
    if (!plan || !planLocale) return {};
    const originalPrice =
      isNewWebPricing &&
      (locale.iso === "AU" || locale.iso === "NZ") &&
      newPricing[plan.name]
        ? newPricing[plan.name]
        : planLocale.price;
    const discountedPrice = discount
      ? calculateFinalPrice({ price: originalPrice }, discount)
      : null;

    const planDurationInMonths =
      plan.duration_unit === "month" ? plan.duration : 12;

    const monthlyOriginalPrice = originalPrice / planDurationInMonths;
    const monthlyDiscountedPrice =
      typeof discountedPrice === "number"
        ? discountedPrice / planDurationInMonths
        : null;

    const formattedOriginalPrice = locale.formatPrice({
      price: originalPrice,
      currency: planLocale.currency,
      forceDecimals: withDecimals,
    });
    const monthlyFormattedPrice = locale.formatPrice({
      price: monthlyOriginalPrice,
      currency: planLocale.currency,
      forceDecimals: withDecimals,
    });

    const formattedDiscountPrice =
      typeof discountedPrice === "number"
        ? locale.formatPrice({
            price: Math.max(discountedPrice, 0),
            currency: planLocale.currency,
            forceDecimals: withDecimals,
          })
        : null;
    const monthlyFormattedDiscountedPrice =
      typeof discountedPrice === "number"
        ? locale.formatPrice({
            price: discountedPrice,
            currency: planLocale.currency,
            forceDecimals: withDecimals,
          })
        : null;

    return {
      originalPrice,
      discountedPrice,
      monthlyOriginalPrice,
      monthlyDiscountedPrice,

      formattedOriginalPrice,
      monthlyFormattedPrice,
      formattedDiscountPrice,
      monthlyFormattedDiscountedPrice,
    };
  }, [locale, planLocale, plan, discount, withDecimals]);
};
